.container.contact__container {
    width: 60%;
}

form {
    display: flex;
    flex-direction:column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
}

/* ================= MEDIA QUERIES (MEDIUM DEVICES) =================== */
/*This one might be not needed*/
@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
    }
}

/* ================= MEDIA QUERIES (SMALL DEVICES) =================== */
@media screen and (max-width: 600px) {
    .container.contact__container {
        width: var(--container-width-sm);
    }
}